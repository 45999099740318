import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';

import { SEO, Layout, PagePresentation, Services, BannerDepannage } from '@/components';
import mq from '@/styles/mq';

import { PageContext } from '@/domain/pageContext';
import { PDepannage } from '@/domain/Depannage';

import theme from '@/styles/theme';

interface Props {
    data: {
        prismicDepannagepage: PDepannage;
    }
    pageContext: PageContext;
}


const Depannage = ({ pageContext, data: { prismicDepannagepage } }: Props): JSX.Element => {

    const { dataHeader, alternate } = pageContext;
    const { uid, lang, alternate_languages } = prismicDepannagepage;
    const { 
      seo_url, 
      seo_title, 
      seo_description, 
      seo_keywords, 
      pretitle_presentation, 
      title_presentation, 
      content_presentation, 
      button_presentation, 
      image_presentation, 
      pretitle_presentation2, 
      title_presentation2, 
      content_presentation2, 
      image_presentation2,
      banner_infos
    } = prismicDepannagepage.data;

    const selectLang = {
        lang: lang,
        uid: uid,
        alternate_languages: alternate_languages
    };

    const pagePresentation = {
        preTitle: pretitle_presentation.raw,
        title: title_presentation.raw,
        content: content_presentation.raw,
        button: button_presentation.raw,
        image: image_presentation
    }

    const pagePresentation2 = {
      preTitle: pretitle_presentation2.raw,
      title: title_presentation2.raw,
      content: content_presentation2.raw,
      image: image_presentation2
    }

    const seo = {
        lang: lang.split('-')[0],
        url: `${lang.split('-')[0]}/${seo_url.text}`,
        title: seo_title.text,
        description: seo_description.text,
        keywords: seo_keywords.text
    }

    const linkAlternate = alternate?.map(data => ({
      lang: data.lang,
      url: `${data.lang.split('-')[0]}/${data.uid}`
    }))

    return (
        <Layout header={dataHeader} selectLang={selectLang} >
            <SEO lang={seo.lang} title={`Global Electrics | ${seo.title}`} url={seo.url} description={seo.description} keywords={seo.keywords} alternate={linkAlternate} />
            <BannerDepannage content={banner_infos} />
            <PagePresentation 
              data={pagePresentation} 
              css={css`
                padding: 2rem 0; 
                ${mq.mqMax('lg')}{
                  padding: 0rem 0 2rem 0;
                }
              `} 
            />
            <PagePresentation 
              data={pagePresentation2} 
              css={css`
                flex-direction: row-reverse; 
                padding: 2rem 0; 
                background-color: ${theme.colors.colorThird};
                p, h1, li{
                  color: ${theme.colors.white};
                }
              `} 
            />
            <Services lang={lang} />
        </Layout>
    );
};

export const query = graphql`
query ($id: String!) {
  prismicDepannagepage(id: {eq: $id}) {
    lang
    uid
    alternate_languages {
      lang
      uid
    }
    data {
      seo_url {
        text
      }
      seo_title {
        text
      }
      seo_description {
        text
      }
      seo_keywords {
        text
      }
      pretitle_presentation {
        raw
      }
      title_presentation {
        raw
      }
      content_presentation {
        raw
      }
      button_presentation {
        raw
      }
      image_presentation {
        alt
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      banner_infos {
        raw
      }
      pretitle_presentation2 {
        raw
      }
      title_presentation2 {
        raw
      }
      content_presentation2 {
        raw
      }
      image_presentation2 {
        alt
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  }
}

`

export default Depannage;
